<template>
<div>
	<CRow>
		<CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="9" class="text-left"><h4>{{labelTitle}}</h4></CCol>
              <CCol col="3" class="text-right"><CButton v-on:click="backtoUserDetail()" color="dark">Back</CButton></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>

           <CForm>


							<CInput
                type="text"
								id="phoneNumberSearch"
                class="phoneNumber"
								autocomplete="off"
                label="Phone Number"
                v-model="phoneNum"
                :readonly=true
								horizontal
              >
							</CInput>

							<CRow>
                <CCol col="12" class="text-left">
                  <CAlert v-bind:color="coloruser" v-if="seenuser" id="alertuser">{{ msguser }}</CAlert>
                </CCol>
              </CRow>

              <CInput
                type="text"
                label="Display Name"
                v-model="displayName"
                :readonly="true"
                horizontal
              />
							<b-row>
                <b-col sm="3">
										<label for="input-small">Features</label>
                </b-col>
                <b-col sm="9">
										<b-form-select v-model="feature" :disabled="!isDisabledcurr" :options="optFeatures"></b-form-select>
                </b-col>
							</b-row><br />

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Wallet Name</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select v-model="walletId" :disabled="!isDisabledcurr" :options="optWallet"></b-form-select>
                   </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Currency</label>
                  </b-col>
                  <b-col sm="9">
                     <b-form-select v-model="currency" :disabled="!isDisabledcurr"  :options="optCurrency"></b-form-select>
                   </b-col>
              </b-row>
              <br />
               <b-row>
                 <b-col sm="3">
                   <label for="input-small">Expired Date</label>
                 </b-col>
                 <b-col sm="5">
                   <CInput type="date" v-model="expiredDate" />
                 </b-col>
                 <b-col sm="4">
                   <vue-timepicker @change="sttimeChange" v-model="expiredTime" format="HH:mm:ss"></vue-timepicker>
                 </b-col>
               </b-row>
               <CInput
                   v-show="seenAdjust !== false"
                   type="number"
                   label="Current Amount "
                   v-model="currAmount"
                   :disabled="!isDisabledamount"
                   min="1"
                   readonly="readonly"
                   placeholder="Enter Amount"
                   @keyup="keyUpAmount"
                   horizontal
               />
               <CInput
                type="number"
                label="Amount "
                v-model="amount"
                :disabled="!isDisabledamount"
                min="1"
                placeholder="Input Amount"
                @keyup="keyUpAmount"
                horizontal
               />

              <span v-if="seenOTP" >
                <CInput
                  type="text"
                  label="Tr Reference"
                  v-model="trRef"
                  :readonly="true"
                  horizontal
                />

                <CInput
                  type="text"
                  label="OTP"
                  v-model="otp"
                  placeholder="Type OTP"
                  horizontal
                />
              </span>

              <b-row>
                  <b-col sm="3"></b-col>
                  <b-col sm="9">
                     <b-button-group>
                     <CButton v-on:click="actionSubmit()" color="primary">Submit</CButton>
                      &nbsp;
                     <CButton v-if="sent" v-on:click="actionResend()" color="success">Resend OTP</CButton>
                     </b-button-group>
                  </b-col>
              </b-row><br/>

              <b-row v-if="pbar">
                  <b-col sm="12">
                   <b-progress :max="max" height="2rem" show-progress animated>
                    <b-progress-bar :value="value" variant="success" :label="`${((value / max) * 100)}%`"></b-progress-bar>
                  </b-progress>
                  </b-col>
              </b-row><br/>

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>

              <CRow>
                <CCol col="12" class="text-left">
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
              </CRow>

            </CForm>

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>



<script>
import AuthService from '@/api/AuthService.js';
import VueTimepicker from 'vue2-timepicker';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import moment from 'moment';

export default {
    name: 'editEarmark',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      VueTypeaheadBootstrap,
      VueTimepicker
    },
    data() {
			var date = new Date();
			var exprDate = new Date(date);
			exprDate.setFullYear(date.getFullYear() + 5);
      return {
          trRef:'',
          otp:'',
					checked: false,
					hiddenChecked : true,
          seenOTP:false,
          sent:false,
          optCustMsg:[
            {"value":1, "text" : "Top-up by ATM Cash Deposit"},
            {"value":2, "text" : "Top-up without top-up Code"},
            {"value":3, "text" : "Reward for Moolahgo Promotion"},
            {"value":4, "text" : "Reward for Identity Verification"},
            {"value":5, "text" : "Refund due to Debit error"},
            {"value":6, "text" : "Refund from Trx Cancellation"},
            {"value":7, "text" : "Others"},
          ],
          seenfile:false,
          cbbfile:false,
          isDisabledSlc: true,
          isDisabledfile:true,
          isDisabledcurr:false,
          isDisabledamount:true,
          isDisabledtrType:true,
          seenBtnDwld:false,
          seenAdjust:false,
          optCurrency: [],
          optNumber:[],
          optWallet:[],
          optFeatures:[],
          phoneNum:'',
          baseItems:[],
          baseItems2:[],
          baseUserId:[],
          walletGroup:[],
					walletGroupAvailable:[],
          basePhoneNum:[],
          optTrType:[],
          reportList:[],
          report:[],
          successList:[],
          failedList:[],
          userId:'',
          dbId:'',
          dataId:'',
          displayName:'',
          expiredDate: exprDate.toISOString().substr(0, 10),
          expiredTime: moment.utc(date).local().format('HH:mm:ss'),
          walletId:'',
          trType:'',
          currency:'',
          purpose:'',
          labelTitle:'',
          currAmount:'',
          amount:'',
          reason:'',
          custMsg:'',
          otherMsg:'',
          feature:'',
          items:[],
          fields: [
            {
              key: 'phoneNumber',
              label : 'Phone Number',
              sortable: true
            },
            {
              key: 'currency',
              label : 'Currency',
              sortable: true
            },
            {
              key: 'amount',
              label : 'Amount',
              sortable: true
            },
          ],
          pbar:false,
          value:0,
          max:0,
          spinner:false,
          currentPage: 1,
          perPage: 5,
          filter: null,
          seenOther: false,
          seen:false,
          msg :'',
          color:'',
          seenuser:false,
          msguser :'',
          coloruser:''
      };
    },
    created () {
        this.getConfigList();
        this.getWalletGroupList();
        this.userId =  this.$route.params.id;
        this.dbId   =  this.$route.params.dbid;
        this.dataId =  this.$route.params.walletId;
        this.getUserDetail(this.$route.params.id);
        this.purpose    = "update";
        this.labelTitle = "Update Earmark";
        if(this.$route.params.purpose === "adjust") {
          this.purpose    = "adjust";
          this.labelTitle = "Add Amount Earmark"
          this.seenAdjust = true;
        }
    },
    methods: {

      actionResend(){
        this.spinner = true;
        this.debitCreditValidate();
      },

      onChangeCsMsg(){

        this.seenOther = this.custMsg === 7;

      },

      keyUpAmount(){
        let newVal = this.amount.toString()
        if (newVal.includes('.')) {
          newVal = newVal.split('.')[0] + '.' + newVal.split('.')[1].slice(0, 2)
        }
        this.amount = newVal
      },

      earMark(){

        this.hiddenChecked = !this.checked;

      },

      getDateTime(){
        var today = new Date();
        var date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        return date + ' ' + time;
      },

      findUserId(phoneNum){
        var basePhoneNum = this.basePhoneNum;   var baseUserId = this.baseUserId;
        let index    = basePhoneNum.findIndex(basePhoneNum => basePhoneNum === phoneNum );
        //console.log(index+"|"+ phoneNum +"=="+ userId);
        return baseUserId[index];
      },

      actionSubmit: async function () {
        var dis      = this;
        this.spinner = true;
        let params   = {};
        let validate = [];
        params["amount"]   = this.amount;
        params["currency"] = this.currency;
        params["walletId"] = this.walletId;
        params["userId"]   = this.userId;

        for (let keys in params) {
            if (!params[keys]) {
              validate.push(false);
            } else {
              validate.push(true);
            }
        }

        console.log(params);

        if (!validate.includes(false)) {
            console.log('submit update earmark');
            try {
                const check_earmark = await AuthService.earMarkByRecordId(this.dataId);
                console.log(check_earmark);
                let arrCheck = check_earmark.payload;
                let paramEarmark = {};
                let expiredAt = this.expiredDate + ' ' + this.expiredTime;
                paramEarmark["expireDate"] = new Date(expiredAt).toISOString();
                paramEarmark["recordId"]   = dis.dataId;
                let amountUpdate = parseFloat(this.amount);
                if(this.purpose === "adjust") {
                    amountUpdate += parseFloat(arrCheck.amount);
                }
                paramEarmark["amount"] = amountUpdate;
                AuthService.earMarkUpdate(paramEarmark).then(resp_earmark => {
                    console.log(resp_earmark);
                    this.msg      = (resp_earmark.statusCode !== "S0000") ? 'failed to update earmark due to : ' + resp_earmark.message : 'update earmark successfully done';
                    this.seen     = true;
                    this.spinner  = false;
                    this.color    = (resp_earmark.statusCode !== "S0000") ? 'warning' : 'success';
                    setTimeout(() => this.seen = false, 5000);
                }, error => {
                    console.log(error);
                });
                this.completeSingleCredit();
                this.spinner = false;
            } catch (err) {
                console.log(err);
            }
        } else {
            this.msg = 'Input not Complete, All Data are Required!';
            this.color = 'warning';
            this.seen = true;
            this.spinner = false;
            setTimeout(() => this.seen = false, 5000);
        }
      },

      backtoUserDetail: function () {
        let userid = this.userId;
        let dbid = this.dbId;
        this.$router.push(`/eWallet/details/${userid}/${dbid}?back=1`);
      },

      completeSingleCredit(){
          this.getUserDetail(this.userId);
      },

      actionReset(){
          this.items = [];
          this.walletId = "";
          this.trType = "";
          this.custMsg= "";
          this.otherMsg="";
          this.reason = "";
          this.trRef='';
          this.otp = '';
          this.sent = false;
          this.seenOTP = false;
          this.cbbfile = false;
          this.seenfile = false;
          this.isDisabledSlc = true;
      },

      actionChange(){
        if(this.phoneNum){
          this.currency='';
          this.amount='';
          this.reason='';
          var temp = this.baseItems2;
          var phoneNum = this.phoneNum;
          var user = this.baseUserId;
          let index = temp.findIndex(temp => temp === phoneNum );
          this.userId = user[index];
          this.getUserDetail(user[index]);
        } else {
          this.msg   = 'Please select phone number first !';
          this.color = 'warning'; this.seen  = true;
          setTimeout( () =>  this.seen=false , 5000);
        }
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      sttimeChange() {
         var term = this.expiredTime; var temp = this.expiredTime;

         if (term.match(/HH|mm|ss/g)) {
            temp = term.replace("HH", "00").replace("mm", "00").replace("ss", "00");
         }

         this.expiredTime = temp;

      },

      debitCreditValidate: function() {
          AuthService.debitCreditValidate(false).then(resp => {
            if(resp.statusCode === 'S0000'){
               this.trRef   = resp.message;
               this.seenOTP = true; this.sent = true;
               this.msg     = "An OTP Code has been sent to your Approver. Please request code from Approver!";
               this.color   = 'info';
            } else {
               this.msg   = resp.message;
               this.color = 'warning';
            }
            this.seen = true; this.spinner =false;
            setTimeout( () =>  this.seen=false , 5000);

           }, error => {
              console.log(error);
          });
      },

      creditUser: function(params) {
        AuthService.creditUser(params).then(resp => {
            //console.log(resp);
              if(resp.message === 'Success'){
                this.msg   = 'Credit user successfully done';
                this.color = 'success';  this.seen  = true;
                this.phoneNum='';
                this.userId='';
                this.displayName='';
                this.walletId='';
                this.currency='';
                this.amount='';
                this.custMsg='';
                this.reason='';
                //this.seen=false;
              } else {
                this.msg   = resp.data.message;
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);
              }
        }, error => {
            console.log(error);
        });
      },

      getUserDetail: function(id) {
					if(id !== undefined && id !== "") {
              AuthService.getUserDetail(id).then(resp => {  //console.log(resp);
              var items = resp.wallets;
              var wallets = [];
              for (var key in items) {
                var WalletId   = items[key].walletId;
                var WalletName = items[key].walletGroup.name;
                wallets.push({ value: WalletId  , text: WalletName });
              }
              AuthService.earMarkByRecordId(this.$route.params.walletId).then(resp => {
                  console.log(resp);
                  var payloads = resp.payload;
                  this.walletId = payloads.walletId;
                  let currAmount = parseFloat(payloads.amount).toFixed(2);
                  this.currAmount = currAmount;
                  this.amount = currAmount;
                  if (this.purpose === "adjust") {
                      this.amount = '';
                  }
                  this.feature = payloads.feature;
                  let exprDate = new Date(payloads.expireDate);
                  this.expiredDate = exprDate.toISOString().substr(0, 10);
                  this.expiredTime = moment.utc(exprDate).local().format('HH:mm:ss')
                  this.currency = payloads.currency;
              }, error => {
                  console.log(error);
              });
              this.phoneNum = resp.userName;
              var displayName = resp.firstName + " " + resp.lastName;
              this.displayName = displayName.trim();
              this.optWallet = wallets;
              //this.optWallet = this.walletGroup;
								if(this.seenuser) this.seenuser=false;
              }, error => {
                this.loading = false;
								document.getElementById("phoneNumberSearch").style.border = "1px solid red";
                console.log(error);
              });
					} else {
							document.getElementById("phoneNumberSearch").style.border = "1px solid red";
							this.coloruser="danger"; this.msguser = "User Not Found";
							this.seenuser=true;
							var dis = this;
							setTimeout( function(){ dis.seenuser=false; document.getElementById("phoneNumberSearch").style.removeProperty("border"); } , 5000);
					}
      },

      getWalletGroupList: function() {
        AuthService.getWalletGroupList().then(resp => {  //console.log(resp);

             var items = resp; var wallets = [];
             for (var key in items) {
                var WalletId   = items[key].id;
                var WalletName = items[key].name;
                wallets.push({ value: WalletId  , text: WalletName });
              }
              this.walletGroup = wallets;

        }, error => {
          this.loading = false;
          console.log(error);
        });
      },

			fetchUsers: function() {
        AuthService.getUserList({page:0, size:10000}).then(resp => { //console.log(resp);

        var users = resp.payload;
        var items = []; var items2 = []; var userId=[]; var basePhoneNum=[];

        for (var key in users) {
            var countryCode = users[key].mobileNumber.countryCode;
            var phoneNum = users[key].mobileNumber.number;
            var ccphoneNum = countryCode+phoneNum;   //console.log(ccphoneNum);
            items.push({ name : ccphoneNum});
            items2.push(ccphoneNum);
            userId.push(users[key].userIdentify.userId);
            basePhoneNum.push(ccphoneNum);
        }

        this.baseItems = items;
        this.baseUserId = userId;
        this.optNumber = items2;
        this.baseItems2 = items2;
        this.basePhoneNum = basePhoneNum;

        }, error => {
          this.loading = false;
          console.log(error);
        });
      },

			sortByProperty(property){
         return function(a,b){
            if(a[property] > b[property])
               return 1;
            else if(a[property] < b[property])
               return -1;
            return 0;
         }
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {   //console.log(resp);

            var currSrc=[]; var temp = [];
            var arrCurrency = resp.currency;
            for (const item of arrCurrency){ temp.push(item); }
            temp.sort();
            for (var key in temp) {
                var curr = temp[key];
                currSrc.push({ value: curr , text: curr });
            }
            this.optCurrency = currSrc;

            var typeSrc=[];
            var arrTypes = resp.systemTransactionTypes;
            for (const item2 of arrTypes) {
                if(item2 !== "REDEEM") { typeSrc.push({ value: item2 , text: item2 }); }
            }

            this.optTrType = typeSrc;

						var gFeatSrc=[];
						var arr_notIncludes = ['P2P_OUTSIDE_GROUP', 'P2P_OUTSIDE_COUNTRY', 'P2P_SEND_UNREG_USER', 'REQUEST_MONEY', 'P2M_PREFERRED_PARTNERS_ONLY', 'BY_INVITE', 'BY_REQUEST', 'TOPUPCARD', 'TOPUP_CHECKOUT', 'TOPUP_BANK', 'TOPUP_AUTO'];
						var groupFeatures = resp.groupFeatures;
						for (var keys in groupFeatures) {
								if(keys === 0) this.feature = groupFeatures[key];
								var gFeat = groupFeatures[keys];
								if(!arr_notIncludes.includes(gFeat)) {
										gFeatSrc.push({ value: gFeat, text: gFeat });
								}
						}
						gFeatSrc.sort(this.sortByProperty("value"));
						this.optFeatures = gFeatSrc;


           }, error => {
            console.log(error);
          });
      },

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
<style>
/* .list-group shadow vbt-autcomplete-list {  background: black; } */
[role~=listbox]{ background: black; }
</style>
